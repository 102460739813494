import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Currency } from '@supy.api/dictionaries';

import { LoadingOverlayModule } from '@supy/components';

import { InfoWidgetComponent } from '../info-widget';
import { TotalTileComponent } from '../total-tile';

export interface TotalsWidgetRow {
  readonly title: string;
  readonly value: number;
}

export interface TotalsWidgetData {
  readonly grandTotal: number;
  readonly rows: TotalsWidgetRow[];
}

@Component({
  selector: 'supy-totals-widget',
  standalone: true,
  imports: [CommonModule, InfoWidgetComponent, TotalTileComponent, LoadingOverlayModule],
  templateUrl: './totals-widget.component.html',
  styleUrl: './totals-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalsWidgetComponent {
  readonly headerTitle = input<string>('Totals');
  readonly currency = input<Currency>();
  readonly currencyPrecision = input<number>();
  readonly isLoading = input<boolean>(false);

  readonly grandTotal = input<number>();
}
