import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'supy-info-tile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './info-tile.component.html',
  styleUrl: './info-tile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTileComponent {
  readonly header = input<string>();
  readonly value = input<string>();
}
