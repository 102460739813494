import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { SupyCommonModule } from '@supy/common';

@Component({
  selector: 'supy-total-tile',
  standalone: true,
  imports: [CommonModule, SupyCommonModule],
  templateUrl: './total-tile.component.html',
  styleUrl: './total-tile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalTileComponent {
  readonly header = input.required<string>();
  readonly value = input.required<number>();
  readonly currency = input<string>();
  readonly currencyPrecision = input<number>();
  readonly isBold = input<boolean>();
  readonly isInteger = input<boolean>();
}
