import { Component, input } from '@angular/core';
import { Currency } from '@supy.api/dictionaries';

import { SupyCommonModule } from '@supy/common';
import { ButtonModule, IconModule, LoadingOverlayModule } from '@supy/components';

@Component({
  selector: 'supy-expandable-content-header',
  standalone: true,
  imports: [ButtonModule, IconModule, SupyCommonModule, LoadingOverlayModule],
  templateUrl: './expandable-content-header.component.html',
  styleUrl: './expandable-content-header.component.scss',
})
export class ExpandableContentHeaderComponent {
  readonly isExpanded = input<boolean>();
  readonly isClickable = input<boolean>(true);
  readonly label = input<string>();
  readonly summaryLabel = input<string>($localize`:@@grandTotal:Grand Total`);
  readonly total = input<number>();
  readonly currency = input.required<Currency>();
  readonly currencyPrecision = input.required<number>();
  readonly isLoading = input<boolean>();
}
