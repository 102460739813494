<div class="supy-info-tile">
  @if (header()) {
    <span class="supy-info-tile__title">{{ header() }}</span>
  } @else {
    <ng-content select="[header]" />
  }
  @if (value()) {
    <span class="supy-info-tile__value">{{ value() }}</span>
  } @else {
    <ng-content select="[value]" />
  }
</div>
