<article class="supy-total-tile" [class.bold]="isBold()">
  @if (header()) {
    <span class="supy-total-tile__header">{{ header() }}</span>
  } @else {
    <ng-content select="[header]" />
  }
  @if (value() !== undefined) {
    <span class="supy-total-tile__value">
      @if (!isInteger()) {
        {{ value() | supyPrecision: currencyPrecision() }} {{ currency() }}
      } @else {
        {{ value() }}
      }
    </span>
  } @else {
    <ng-content select="[value]" />
  }
</article>
