<supy-info-widget>
  <supy-loading-skeleton [isLoading]="isLoading()" />

  <div class="supy-totals-widget">
    <span class="supy-totals-widget__header" i18n="@@totals">{{ headerTitle() }}</span>

    <div class="supy-totals-widget__content">
      <ng-content select="[content]" />
    </div>

    <div class="supy-totals-widget__footer">
      @if (grandTotal() !== undefined) {
        <supy-total-tile
          [isBold]="true"
          header="Grand Total"
          [value]="grandTotal()"
          [currency]="currency()"
          [currencyPrecision]="currencyPrecision()"
        />
      } @else {
        <ng-content select="[grand-total]" />
      }
    </div>
  </div>
</supy-info-widget>
