<div class="supy-expandable-content-header" [class.clickable]="isClickable()">
  @if (label()) {
    <span class="supy-expandable-content-header__label-container">
      <ng-content select="[action-prefix]"></ng-content>
      <span class="supy-expandable-content-header__label">{{ label() }}</span>
      <supy-icon [name]="isExpanded() ? 'arrow-up-2' : 'arrow-down-1'" />
    </span>
  } @else {
    <ng-content></ng-content>
  }

  <div class="supy-expandable-content-header__summary">
    <supy-loading-skeleton [isLoading]="isLoading()" />

    <span class="supy-expandable-content-header__summary--label">{{ summaryLabel() }}</span>
    <span class="supy-expandable-content-header__summary--value">
      {{ total() | supyPrecision: currencyPrecision() : true }} {{ currency() }}
    </span>
  </div>
</div>
